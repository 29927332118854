import apiClient from '@/services/axios'

const getAll = async () => {
  const res = await apiClient.get('/patientTarget')
  if (res) {
    return res.data
  } else {
    return []
  }
}

const getById = async (id) => {
  const res = await apiClient.get(`/patientTarget/${id}`)
  if (res) {
    return res.data
  } else {
    return null
  }
}

const getByDate = async (month, year) => {
  const res = await apiClient.get(`/patientTarget/${month}/${year}`)
  if (res) {
    return res.data
  } else {
    return null
  }
}

const deleteById = async (id) => {
  const res = await apiClient.delete(`/patientTarget/${id}`)
  if (res) {
    return res.data
  } else {
    return null
  }
}

const create = async (data) => {
  const res = await apiClient.post('/patientTarget', data, {})
  if (res) {
    return res
  } else {
    return null
  }
}

const editById = async (id, data) => {
  const res = await apiClient.patch(`/patientTarget/${id}`, data, {})
  if (res) {
    return res
  } else {
    return null
  }
}

export {
  getAll,
  getById,
  deleteById,
  create,
  getByDate,
  editById,
}
