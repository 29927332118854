<template>
  <div>
    <div class="mb-4">
      <a-table
        :columns="columns"
        :dataSource="items"
        :pagination="true"
        :class="$style.table"
        rowKey="id"
        :loading="isLoading"
      >
        <template slot="month" slot-scope="month, record">
          <div>
            <div v-if="isAdmin">
              <div>
                <router-link
                  :to="{ path: '/target-pasien/details/' + record.id }"
                  class="kit__utils__link font-size-16"
                  >{{ month ? month : '-' }}</router-link
                >
              </div>
            </div>
            <div v-if="!isAdmin">{{ month ? month : '-' }}</div>
            <div class="text-gray-4">{{ record.year ? record.year : '-' }}</div>
          </div>
        </template>
        <template slot="total" slot-scope="total">
          <div>
            <div>{{ total }}</div>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>
<script>
import data from './data.json'
import { getAll } from '@/services/axios/api/targetpasien'
import moment from 'moment'
import router from '@/router'

const columns = [
  {
    title: 'Date',
    dataIndex: 'month',
    className: 'bg-transparent text-gray-6',
    scopedSlots: { customRender: 'month' },
    fixed: 'left',
    sorter: (a, b) => {
      return a.month.localeCompare(b.month)
    },
  },
  {
    title: 'Total',
    dataIndex: 'total',
    className: 'bg-transparent text-gray-6',
    scopedSlots: { customRender: 'total' },
    sorter: (a, b) => {
      return a.total.localeCompare(b.total)
    },
  },
]

export default {
  name: 'TabelPasien',
  data: function () {
    return {
      columns,
      data,
      items: [],
      isLoading: true,
      isAdmin: false,
    }
  },
  created() {
    this.getAllTargetpatient()
    this.getRole()
  },
  methods: {
    moment,
    getRole() {
      const role = window.localStorage.getItem('role')
      // console.log(role)
      if (role === 'admin') {
        this.isAdmin = true
      }
    },
    async getAllTargetpatient() {
      const res = await getAll()
      console.log(res[0].month + '-' + res[0].year, 'month')
      for (const i in res) {
        res[i].month = moment('0' + res[i].month + '-01-' + res[i].year).format(
          'MMM',
        )
      }
      const data = res.filter((target) => target.status === 1)
      this.isLoading = false
      this.items = data
    },
    viewDetails(id) {
      router.push('/pasien/details/' + id)
    },
  },
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>
